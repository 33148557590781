import Api from "@/services/api";
import { AxiosResponse } from "axios";
import { TicketsType } from "@/interfaces/TicketsType";
import { Ticket } from "@/interfaces/Ticket";
import { Priority } from "@/interfaces/priority";
import { MediaObject } from "@/interfaces/MediaObject";
import { StatusTickets } from "@/interfaces/StatusTickets";
import { SearchParamTickets } from "@/interfaces/SearchParamTickets";
import MAxiosError from "@/exception/MAxiosError";
import { Commentary } from "@/interfaces/Commentary";

export class TicketsServices {
  static async getData(id: string): Promise<Ticket> {
    try {
      const result: AxiosResponse = await Api().get(`/api/tickets/${id}`);
      const ticket: Ticket = result.data;
      return ticket;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async listType(): Promise<Array<TicketsType>> {
    try {
      const result: AxiosResponse = await Api().get("/api/ticket_types");
      const types: Array<TicketsType> = result.data["hydra:member"];
      return types;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async listStatus(): Promise<Array<StatusTickets>> {
    try {
      const result: AxiosResponse = await Api().get("/api/ticket_statuses");
      const status: Array<StatusTickets> = result.data["hydra:member"];
      return status;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async listPriority(): Promise<Array<Priority>> {
    try {
      const result: AxiosResponse = await Api().get("/api/ticket_priorities");
      const priority: Array<Priority> = result.data["hydra:member"];
      return priority;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async searchTicketsWithClient(
    client: string,
    project: string,
    dateIni: string,
    dateFin: string
  ): Promise<Array<Ticket>> {
    try {
      const urlDateIni: string = !dateIni
        ? ""
        : `?initialDate[strictly_before]=${dateIni}`;
      const urlDateFin: string = !dateFin
        ? ""
        : `&initialDate[strictly_after]=${dateFin}`;
      const result: AxiosResponse = await Api().get(
        `/api/clients/${client}/projects/${project}/tickets${urlDateIni}${urlDateFin}`
      );
      const tickets: Array<Ticket> = result.data["hydra:member"];
      return tickets;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static validateParams(
    status: string | null | undefined,
    dateIni: string | null | undefined,
    dateFin: string | null | undefined,
    type: string,
    department: string
  ): Partial<SearchParamTickets> {
    const queryParams: Partial<SearchParamTickets> = {};
    queryParams["order[createdAt]"] = "DESC";
    if (status && typeof status !== "undefined") queryParams.status = status;
    if (dateIni && dateFin) {
      queryParams[`createdAt[strictly_after]`] = dateIni;
      queryParams[`createdAt[strictly_before]`] = dateFin;
    }
    if (dateIni && !dateFin) {
      queryParams[`createdAt[strictly_after]`] = dateIni;
    }
    if (!dateIni && dateFin) {
      queryParams[`createdAt[strictly_before]`] = dateFin;
    }
    if (type) {
      queryParams[`ticketType.id`] = type;
    }
    if (department) {
      queryParams[`department.id`] = department;
    }
    return queryParams;
  }
  static async searchTicketsRequestedByExtranetUser(
    client: string,
    project: string,
    user: string,
    dateIni: string | null | undefined,
    dateFin: string | null | undefined,
    type: string,
    department: string,
    status: string | null | undefined
  ): Promise<Array<Ticket>> {
    try {
      const queryParams: Partial<SearchParamTickets> = this.validateParams(
        status,
        dateIni,
        dateFin,
        type,
        department
      );
      const result: AxiosResponse = await Api().get(
        `/api/extranet_users/${user}/tickets_requested`,
        { params: queryParams }
      );
      return result.data["hydra:member"];
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async searchTicketsByProject(
    project: string,
    status: string | null | undefined,
    dateIni: string | null | undefined,
    dateFin: string | null | undefined,
    type: string,
    department: string
  ): Promise<Array<Ticket>> {
    try {
      const queryParams: Partial<SearchParamTickets> = this.validateParams(
        status,
        dateIni,
        dateFin,
        type,
        department
      );
      const result: AxiosResponse = await Api().get(
        `/api/projects/${project}/tickets`,
        { params: queryParams }
      );
      const tickets: Array<Ticket> = result.data["hydra:member"];
      return tickets;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async searchTicketsByStatus(
    client: string,
    project: string,
    status: string | null | undefined,
    dateIni: string | null | undefined,
    dateFin: string | null | undefined,
    type: string,
    department: string
  ): Promise<Array<Ticket>> {
    try {
      const queryParams: Partial<SearchParamTickets> = this.validateParams(
        status,
        dateIni,
        dateFin,
        type,
        department
      );
      const result: AxiosResponse = await Api().get(
        `/api/clients/${client}/projects/${project}/tickets`,
        { params: queryParams }
      );
      const tickets: Array<Ticket> = result.data["hydra:member"];
      return tickets;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async createTicket(params: Ticket): Promise<Ticket> {
    try {
      const result: AxiosResponse = await Api().post("/api/tickets", params);
      const ticket: Ticket = result.data;
      return ticket;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async updateTicket(params: Ticket): Promise<Ticket> {
    try {
      const result: AxiosResponse = await Api().put(
        `/api/tickets/${params.id}`,
        params
      );
      const ticket: Ticket = result.data;
      return ticket;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async createCommentary(commentary: Commentary): Promise<Commentary> {
    try {
      const result: AxiosResponse = await Api().post(
        "/api/ticket_comments",
        commentary
      );
      const comm: Commentary = result.data;
      return comm;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async readCommentary(
    id: string,
    commentary: Commentary
  ): Promise<Commentary> {
    try {
      const result: AxiosResponse = await Api().put(
        `/api/ticket_comments/${id}`,
        commentary
      );
      const comm: Commentary = result.data;
      return comm;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async createMediaObject(file: File): Promise<MediaObject> {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const result: AxiosResponse = await Api().post(
        "/api/ticket_document_media_objects",
        formData
      );
      const media: MediaObject = result.data;
      return media;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async getMediaObject(id: string): Promise<MediaObject> {
    try {
      const result: AxiosResponse = await Api().get(
        `/api/ticket_document_media_objects/${id}`
      );
      const media: MediaObject = result.data;
      return media;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
  static async removeMediaObject(id: string): Promise<boolean> {
    try {
      await Api().delete(`/api/ticket_document_media_objects/${id}`);
      return true;
    } catch (e) {
      throw new MAxiosError(e);
    }
  }
}
