import { createStore } from "vuex";
import userActions from "@/store/actions/user";
import userMutations from "@/store/mutations/user";
import userGetters from "@/store/getters/user";
import ticketsMutations from "@/store/mutations/tickets";
import ticketsGetters from "@/store/getters/tickets";
import ticketsActions from "@/store/actions/tickets";
import projectGetters from "@/store/getters/project";
import projectActions from "@/store/actions/projects";
import projectMutations from "@/store/mutations/projects";
import depActions from "@/store/actions/dep";
import depMutations from "@/store/mutations/dep";
import depGetters from "@/store/getters/dep";
import { StoreType } from "@/interfaces/StoreType";
import { UserRole } from "@/interfaces/UserRole";
export default createStore<StoreType>({
  state: {
    user: {
      password: "",
      customer: { id: "", name: "" },
      email: "",
      roles: [UserRole.ADMIN, UserRole.CLIENT],
    },
    token: "",
    statusTickets: { closed: 0, inProgress: 0, opens: 0 },
    projects: [],
    project: { name: "", id: "", "@id": "" },
    ticketType: [],
    dep: [],
    tickets: [],
    priorities: [],
  },
  getters: {
    ...ticketsGetters,
    ...projectGetters,
    ...depGetters,
    ...userGetters,
  },
  mutations: {
    ...userMutations,
    ...ticketsMutations,
    ...projectMutations,
    ...depMutations,
  },
  actions: {
    ...userActions,
    ...ticketsActions,
    ...projectActions,
    ...depActions,
  },
  modules: {},
});
