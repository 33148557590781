import axios from "axios";
export default () => {
  const token: string | null = localStorage.getItem("tokenAuths");
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
      Accept: "application/ld+json",
      "Content-Type": "application/ld+json",
      Authorization: "Bearer " + token,
    },
  });

  // interceptor
  api.interceptors.request.use(
    (request) => {
      // console.log("request", request);
      return request;
    },
    (error) => {
      console.log("error", error);
      return error;
    }
  );
  return api;
};
