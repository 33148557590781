import { ActionType } from "@/store/actions/type";
import { TicketsServices } from "@/services/tickets.services";
import { TicketsType } from "@/interfaces/TicketsType";
import { MutationType } from "@/store/mutations/type";
import { Ticket } from "@/interfaces/Ticket";
import { SearchParamTickets } from "@/interfaces/SearchParamTickets";
import { Priority } from "@/interfaces/priority";
import { MediaObject } from "@/interfaces/MediaObject";
import { StatusTickets } from "@/interfaces/StatusTickets";
import { Commit } from "vuex";
import { Commentary } from "@/interfaces/Commentary";

export default {
  [ActionType.LIST_TICKET_TYPES]: async ({
    commit,
  }: {
    commit: Commit;
  }): Promise<Array<TicketsType> | boolean> => {
    try {
      const response: Array<TicketsType> = await TicketsServices.listType();
      commit(MutationType.SET_TICKET_TYPES, response);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.SEARCH_MY_TICKETS]: async (
    { commit }: { commit: Commit },
    params: SearchParamTickets
  ): Promise<Array<Ticket> | boolean> => {
    try {
      const response: Array<Ticket> =
        await TicketsServices.searchTicketsRequestedByExtranetUser(
          params.client,
          params.project,
          params.user,
          params.dateIni,
          params.dateFin,
          params.type,
          params.department,
          params.status
        );
      commit(MutationType.SET_TICKETS, response);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.SEARCH_TICKETS_BY_PROJECT]: async (
    { commit }: { commit: Commit },
    params: SearchParamTickets
  ): Promise<Array<Ticket> | boolean> => {
    try {
      const response: Array<Ticket> =
        await TicketsServices.searchTicketsByProject(
          params.project,
          params.status,
          params.dateIni,
          params.dateFin,
          params.type,
          params.department
        );
      commit(MutationType.SET_TICKETS, response);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.SEARCH_TICKETS]: async (
    { commit }: { commit: Commit },
    params: SearchParamTickets
  ): Promise<Array<Ticket> | boolean> => {
    try {
      const response: Array<Ticket> =
        await TicketsServices.searchTicketsByStatus(
          params.client,
          params.project,
          params.status,
          params.dateIni,
          params.dateFin,
          params.type,
          params.department
        );
      commit(MutationType.SET_TICKETS, response);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.LIST_PRIORITIES]: async ({
    commit,
  }: {
    commit: Commit;
  }): Promise<Array<Priority> | boolean> => {
    try {
      const response: Array<Priority> = await TicketsServices.listPriority();
      commit(MutationType.SET_PRIORITIES, response);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.ADD_COMMENTARY]: async (
    { commit }: { commit: Commit },
    data: Commentary
  ): Promise<Commentary | boolean> => {
    try {
      const response: Commentary = await TicketsServices.createCommentary(data);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.READ_COMMENTARY]: async (
    { commit }: { commit: Commit },
    data: { id: string; commentary: Commentary }
  ): Promise<Commentary | boolean> => {
    try {
      const response: Commentary = await TicketsServices.readCommentary(
        data.id,
        data.commentary
      );
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.CREATE_TICKET]: async (
    { commit }: { commit: Commit },
    data: Ticket
  ): Promise<Ticket | boolean> => {
    try {
      const response: Ticket = await TicketsServices.createTicket(data);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.UPDATE_TICKETS]: async (
    { commit }: { commit: Commit },
    data: Ticket
  ): Promise<Ticket | boolean> => {
    try {
      const response: Ticket = await TicketsServices.updateTicket(data);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.CREATE_MEDIA_OBJECT]: async (
    { commit }: { commit: Commit },
    data: File
  ): Promise<MediaObject | boolean> => {
    try {
      const response: MediaObject = await TicketsServices.createMediaObject(
        data
      );
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.REMOVE_MEDIA_OBJECT]: async (
    { commit }: { commit: Commit },
    id: string
  ): Promise<boolean> => {
    try {
      const response: boolean = await TicketsServices.removeMediaObject(id);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.FIND_MEDIA_OBJECT]: async (
    { commit }: { commit: Commit },
    id: string
  ): Promise<MediaObject | boolean> => {
    try {
      const response: MediaObject = await TicketsServices.getMediaObject(id);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.GET_DATA_ONE_TICKET]: async (
    { commit }: { commit: Commit },
    id: string
  ): Promise<Ticket | boolean> => {
    try {
      const response: Ticket = await TicketsServices.getData(id);
      return response;
    } catch (e) {
      return false;
    }
  },
  [ActionType.SEARCH_STATUS_TICKETS]: async (): Promise<
    Array<StatusTickets> | boolean
  > => {
    try {
      const response: Array<StatusTickets> = await TicketsServices.listStatus();
      return response;
    } catch (e) {
      return false;
    }
  },
};
